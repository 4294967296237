import {Component, Input} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {SplittedCostTableElement} from '../../../interfaces';



@Component({
    selector: 'app-splitted-costs-table-v2',
    templateUrl: './splitted-costs-table-component-v2.component.html',
    styleUrls: ['./splitted-costs-table-component-v2.component.scss']
})
export class SplittedCostsTableComponentV2 {

    public budgetDataSource: MatTableDataSource<SplittedCostTableElement> = new MatTableDataSource([]);
    public displayColumns = [];

    @Input()
    private isExport = false;

    get isExportBudget() {
        return this.isExport;
    }

    @Input()
    set isExportBudget(isExport: boolean) {
        this.isExport = isExport;
        if (isExport) {
            this.displayColumns = ['name', 'cost', 'profit', 'commission', 'expenses', 'total', 'totalEuro', 'taxes'];
        } else {
            this.displayColumns = ['name', 'cost', 'profit', 'commission', 'expenses', 'total'];
        }
    }

    @Input()
    set summary(newTableData: SplittedCostTableElement[]) {
        if (!newTableData) {
            return;
        }
        console.log('updating splittec cost table', newTableData);
        this.budgetDataSource.data = newTableData;
    }
}
