import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function marginSumValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!(control instanceof FormGroup)) {
            return null; // Only apply to FormGroup
        }

        const formGroup = control as FormGroup;
        const commissions = formGroup.get('commissions')?.value || 0;
        const generalExpenses = formGroup.get('generalExpenses')?.value || 0;
        const profit = formGroup.get('profit')?.value || 0;

        const sum = commissions + generalExpenses + profit;

        if (sum > 100) {
            return { marginSumExceeded: true };
        }

        return null;
    };
}