<mat-card>
    <div class="bal-mat-head">
        <div class="bal-mat-head-num">
            <div *ngIf="budget$ | async; let budget">
                <a [routerLink]="['/budget',budget.id]">
                    <h4>Project: {{ budget | projectNr:true }}&nbsp;-&nbsp;{{ budget.prosperworks?.name }}</h4>
                </a>
                <button (click)="exportXlsx()" class="btn btn-primary">Export to XLSX</button>
                <table>
                    <tr>
                        <td>Total (Budget):</td>
                        <td>{{ budget.costSummary?.hoursValue | currency:budget.export ? 'USD' : 'EUR' }}</td>
                        <td>{{ budget.costSummary?.total | currency:budget.export ? 'USD' : 'EUR' }}</td>
                    </tr>
                    <tr>
                        <td> Total Activity:</td>
                        <td>{{ activitiesTotalCost | currency:budget.export ? 'USD' : 'EUR' }}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Difference:</td>
                        <td>{{ budget.costSummary?.hoursValue - activitiesTotalCost | currency:budget.export ? 'USD' : 'EUR' }}</td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="bal-mat-head-icon">
            <a [routerLink]="['/balances/materials/',budgetId]">Go To Materials</a>
        </div>
        <div class="bal-mat-head-icon">
            <a [routerLink]="['/balances/summary/',budgetId]">Go To Summary</a>
        </div>
    </div>
</mat-card>

<mat-card>
    <div class="example-container mat-elevation-z8">

        <mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="sent">
                <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Sent</mat-header-cell>
                <mat-cell *matCellDef="let row">
          <span *ngIf="row.sent" [matTooltip]="row.sentOn">
            <mat-icon>mail</mat-icon>
          </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="budgetId">
                <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Budget</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <span *ngIf="row.budget.project">{{ row.budget | projectNr }}-</span>{{ row.budget.budgetNr }}
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="subBudgetId">
                <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Sub Budget</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.subBudget.description }}</mat-cell>
            </ng-container>


            <ng-container matColumnDef="activityId">
                <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Activity</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.activity.activity }}</mat-cell>
            </ng-container>


            <ng-container matColumnDef="hours">
                <mat-header-cell *matHeaderCellDef i18n>Hours</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.hours }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="hourlyCost">
                <mat-header-cell *matHeaderCellDef i18n>H.Cost</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.actualCost | currency:budget?.export ? 'USD' : 'EUR' }}
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Date (Europe/Rome Time)</mat-header-cell>
                <mat-cell *matCellDef="let row">{{ row.date | timestamp | date:mediumDate:'+0200' }}</mat-cell>
            </ng-container>


            <ng-container matColumnDef="userId">
                <mat-header-cell *matHeaderCellDef i18n mat-sort-header>User</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.user.displayName }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="totalValue">
                <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Cost</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.totalValue | currency:budget?.export ? 'USD' : 'EUR' }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef i18n>Description</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div [innerHtml]="row.description"></div>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row (click)="expandedElement = row" *matRowDef="let row; columns: displayedColumns;" [class.expanded]="expandedElement == row"
                     class="element-row" matRipple>
            </mat-row>
        </mat-table>

        <mat-paginator [length]="dataSource?.resultsLength" [pageSizeOptions]="[25, 50, 100, 1000]"></mat-paginator>
    </div>
</mat-card>
