import {BehaviorSubject, combineLatest, merge, Observable} from 'rxjs';

import {debounceTime, filter, map, switchMap} from 'rxjs/operators';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Budget} from '../budget/interfaces';
import {DataService} from '../services/data.service';
import {Timereport} from '../timereport/interfaces';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import {ProjectNrPipe} from '../shared/pipes/project-nr.pipe';
import {DataSource} from '@angular/cdk/table';
import {AppStateService} from '../services/app-state.service';

import {firestoreDateToJsDate} from '../shared/utils/utils';
import * as XLSX from 'xlsx';
import * as moment from 'moment/moment';



@Component({
    selector: 'app-activities-list',
    templateUrl: './activities-list.component.html',
    styleUrls: ['./activities-list.component.scss']
})
export class ActivitiesListComponent implements OnInit, OnDestroy {

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    public budgetId;
    public budget$: Observable<Budget>;

    public activitiesList$: Observable<Timereport[]>;
    public activitiesTotalCost: number;
    // For the mat-table
    displayedColumns =
        ['sent', 'budgetId', 'activityId', 'hours', 'hourlyCost', 'date', 'userId', 'description', 'totalValue'];
    dataSource: TimereportDataSource;
    private activityChange;
    private subscription;

    constructor(
        private dataService: DataService,
        private route: ActivatedRoute,
        public appState: AppStateService,
        private projectNrPipe: ProjectNrPipe
    ) {
    }

    ngOnInit() {

        this.activityChange = this.route.paramMap.pipe(
            switchMap((params) => {
                this.budgetId = params.get('budgetId');
                return combineLatest([
                    this.dataService.getSimpleBudget(this.budgetId),
                    this.dataService.getTimereportByBudget(this.budgetId)
                ]);
            }));

        this.budget$ = this.activityChange.pipe(map(data => data[0]));

        this.activitiesList$ = this.activityChange.pipe(
            map(data => data[1]),
            map((trList: any[]) => trList.map((tr) => {
                    const data = tr.payload.doc.data() as Timereport;
                    const id = tr.payload.doc.id;

                    if (!('totalValue' in data)) {
                        try {
                            data.totalValue = data.hours * data.actualCost;
                        } catch (err) {
                            data.totalValue = 0;
                        }
                    }
                    return {id: id, ...data};
                }),
                filter((r: any) => !r.user.external)));

        this.subscription = this.activitiesList$.pipe(
            map((tl) => tl.filter(t => !Number.isNaN(t.totalValue)).map(t => t.totalValue).reduce((a, b) => a + b, 0)))
            .subscribe(val => {
                this.activitiesTotalCost = val;
            });

        // this.activitiesList$.pipe(take(1)).subscribe(res => {

        // })

        // Assign the data to the data source for the table to render
        console.log('init here', this.paginator, this.sort);
        this.dataSource = new TimereportDataSource(
            this.activitiesList$,
            this.paginator, this.sort, this.projectNrPipe);

    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    exportXlsx() {
        const data: any[] = [['Budget', 'Activity', 'Hours', 'H.Cost', 'Cost', 'Date (Europe/Rome Time)', 'User', 'Description']];

        this.dataSource._dataChange.value.map(row => {
            const dateString = moment(row.date.toMillis()).format('DD/MM/YYYY');
            data.push([
                row.budget.project ? row.budget.project.projectNr : row.budget.budgetNr,
                row.activity.activity,
                row.hours,
                row.actualCost,
                row.totalValue,
                dateString?.toLowerCase() !== 'invalid date' ? dateString : '',
                row.user.displayName,
                row.description]);
        });


        const wb = XLSX.utils.book_new();
        const ws1 = XLSX.utils.aoa_to_sheet(data);
        ws1['!cols'] = this.displayedColumns.map(i => {
            return {wch: i.length + 10};
        });
        XLSX.utils.book_append_sheet(wb, ws1, 'Overview');
        const fileName = `activities_list_${moment().format('DDMMYYYY_HHmm')}_.xlsx`;
        XLSX.writeFile(wb, fileName);

    }
}


/**
 * Data source to provide what data should be rendered in the table. Note that the data source
 * can retrieve its data in any way. In this case, the data source is provided a reference
 * to a common data base, ExampleDatabase. It is not the data source's responsibility to manage
 * the underlying data. Instead, it only needs to take the data and send the table exactly what
 * should be rendered.
 */
class TimereportDataSource extends DataSource<Timereport> {

    _filterChange = new BehaviorSubject('');
    _dataChange = new BehaviorSubject([]);
    // The number of issues returned by github matching the query.
    public resultsLength = 0;
    public isLoadingResults = false;

    constructor(private observable,
                private _paginator: MatPaginator,
                private _sort: MatSort,
                private projectNrPipe: ProjectNrPipe) {
        super();
        this.observable.subscribe(this._dataChange);
    }

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<Timereport[]> {
        // Listen for any changes in the base data, sorting, filtering, or pagination
        const displayDataChanges = [
            this.observable,
            this._sort.sortChange,
            this._filterChange.pipe(debounceTime(500)),
            this._paginator.page
        ];

        this._paginator._changePageSize(Number(localStorage.getItem('activityListPageSize')))
        this._paginator.page.next(new PageEvent());
        this._paginator.page.subscribe(paginationData => {
            if (paginationData.pageSize) localStorage.setItem('activityListPageSize', paginationData.pageSize.toString());
        })

        const sortId = localStorage.getItem('activityListSortId');
        const sortDirection = localStorage.getItem('activityListSortDirection');

        this._sort.sort(({id: sortId, start: sortDirection}) as MatSortable);

        return merge(...displayDataChanges).pipe(map(() => {
            // console.log('Combine ', data, filter, page);
            const data = this._dataChange.value;

            // ???????????????????????????????
            // Filter
            const fData = data.filter((item: Timereport) => {
                if (!item.activity) {
                    return false;
                }
                let searchStr = (item.budget.budgetNr + item.activity.activity + item.user.displayName).toLowerCase();
                if (item.budget.project) {
                    searchStr += this.projectNrPipe.transform(item.budget).toLowerCase();
                }
                return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
            });

            this.resultsLength = fData.length;
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            this.sortData(fData);
            return fData.splice(startIndex, this._paginator.pageSize);
        }));

    }

    disconnect() {
    }

    private sortData = (timereport: Timereport[]) => {

        localStorage.setItem('activitiesListSortId', this._sort.active);
        localStorage.setItem('activitiesListSortDirection', this._sort.direction);
        // console.log('sort ', this._sort.active, this._sort.direction);
        if (this._sort.active === undefined || this._sort.direction === undefined) {
            return;
        }

        timereport.sort((a: Timereport, b: Timereport) => {

            let propertyA: number | string | Date = '';
            let propertyB: number | string | Date = '';

            switch (this._sort.active) {
                case 'budget':
                    const abid = (typeof a.budget === 'object') ? a.budget.budgetNr : a.budget;
                    const bbid = (typeof b.budget === 'object') ? b.budget.budgetNr : b.budget;
                    [propertyA, propertyB] = [abid, bbid];
                    break;
                case 'userId':
                    [propertyA, propertyB] = [a.user.displayName, b.user.displayName];
                    break;
                case 'activity':
                    [propertyA, propertyB] = [a.activity.activity, b.activity.activity];
                    break;
                case 'hours':
                    [propertyA, propertyB] = [a.hours, b.hours];
                    break;
                case 'date':
                    [propertyA, propertyB] = [firestoreDateToJsDate(a.date), firestoreDateToJsDate(b.date)];
                    break;
                case 'user':
                    [propertyA, propertyB] = [a.user.email, b.user.email];
                    break;
            }

            let valueA;
            let valueB;

            if (propertyA instanceof Date) {
                valueA = propertyA;
                valueB = propertyB;
            } else {
                valueA = isNaN(+propertyA) ? propertyA : +propertyA;
                valueB = isNaN(+propertyB) ? propertyB : +propertyB;
            }

            return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
        });
    }
}
