<mat-card>
    <mat-card-title>
        Materials summary&nbsp;<button matTooltip="export as csv" (click)="exportCsv()" mat-icon-button>
        <mat-icon>cloud_download</mat-icon>
    </button>
    </mat-card-title>
    <div class="example-container mat-elevation-z8">
        <mat-card>
            <mat-form-field>
                <input (keyup)="applyFilter($event.target.value)" matInput placeholder="Filter">
            </mat-form-field>
            <span>Filtered {{ filteredTotal | currency:currency }}</span>
        </mat-card>


        <mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="orderNr">
                <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Order</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <a [routerLink]="['/order/view',row.orderId]">{{ row.orderNr }}</a>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="project">
                <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Project</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.item.budget.project?.projectNr }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="author">
                <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Author</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div>{{ row.author }}</div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="vendor">
                <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Vendor</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div> {{ row.vendor }}</div>
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Date</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.date._seconds * 1000 | timestamp | date }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="unit">
                <mat-header-cell *matHeaderCellDef i18n>Unit</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.item.unit }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="quantity">
                <mat-header-cell *matHeaderCellDef i18n>Quantity</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.item.quantity }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="costEach">
                <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Price</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.item.costEach | currency:currency }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="total">
                <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Total</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.total | currency:currency }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Description</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.item.description | ellipsis:100 }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
        </mat-table>

        <mat-paginator [length]="dataSource.resultsLength" [pageSizeOptions]="[25, 50, 100, 1000]"></mat-paginator>

        <mat-card>Material total:&nbsp;{{ materialTotal | currency:currency }} - Filtered
            {{ filteredTotal | currency:currency }}
        </mat-card>
    </div>

</mat-card>
