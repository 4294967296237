import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DataService} from '../../services/data.service';
import {MatDialog} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {ConfirmDialogComponent} from '../../private/private.component';
import {MarginsTemplate} from '../../budget/interfaces/templates';
import {MatSnackBar} from '@angular/material/snack-bar';



@Component({
    selector: 'app-margins',
    templateUrl: './margins.component.html',
    styleUrls: ['./margins.component.scss']
})
export class MarginsComponent implements OnInit, OnDestroy {
    latestTemplate$: Observable<MarginsTemplate>;
    private unsubscribe$ = new Subject();
    public templateForm: FormGroup;

    @Input() isExport = false;

    constructor(private dataService: DataService,
                public dialog: MatDialog,
                private fb: FormBuilder,
                private _snackbar: MatSnackBar) {
    }

    ngOnInit(): void {
        this.templateForm = this.fb.group({
            id: null,
            margins: this.fb.group({
                generalExpenses: 0,
                commission: 0,
                ciTaxesPercentage: 0,
                profit: 0,
                capitalImprovement: true
            }),
            latest: true,
            validFrom: new Date(),
            validTo: null
        });

        this.latestTemplate$ = this.dataService.getLatestMarginsBluePrints(this.isExport);

        this.latestTemplate$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(template => {
                this.templateForm.patchValue(template);
            });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    submit() {
        const dialogMessage = {confirmMessage: `Are you sure you want to save the new blueprints?`};
        const dialogData = {data: dialogMessage, height: '250px', width: '600px', disableClose: true};
        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogData);

        dialogRef.afterClosed().subscribe(async choice => {
            if (choice.confirm) {
                const newTemplate = this.templateForm.value;
                await this.dataService.addLatestMarginsBluePrints(newTemplate, this.isExport);
                this._snackbar.open('Margin form updated', 'Close', {duration: 5000});
            }
        });
    }


}
