import {Budget} from '../interfaces';



export class BudgetModel {

    static isEditableStatus(budget: Budget) {
        if (!budget) return false;
        return !['closed', 'archived', 'new'].includes(budget.status);
    }

}
