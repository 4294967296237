import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {debounceTime, distinctUntilChanged, take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AuthService} from '../../../../services/auth.service';
import {DataService} from '../../../../services/data.service';
import {Budget, MarginV1} from '../../../interfaces';
import {BudgetModel} from '../../../models/budget-model';



@Component({
    selector: 'app-margin-form-v1',
    templateUrl: './margin-form-component-v1.component.html',
    styleUrls: ['./margin-form-component-v1.component.scss']
})
export class MarginFormComponentV1 implements OnInit, OnDestroy {

    public formUpdating = false;
    @Input() kind: 'ITA' | 'USA' = 'ITA';
    public marginForm: FormGroup;
    private unsubscribe$ = new Subject();

    @Output() updateCostSummary = new EventEmitter<string>();

    constructor(private dataService: DataService, private fb: FormBuilder, private auth: AuthService) {
    }

    private _budget: Budget;

    get budget() {
        return this._budget;
    }

    @Input() set budget(b: Budget) {
        this._budget = b;

        if (this.marginForm) {
            this.patchForm();
        } else {
            console.log('Margin form not ready yet');
        }
    }

    public _authorizedToModify = false;

    public get authorizedToModify() {
        return this._authorizedToModify;
    }

    @Input()
    public set authorizedToModify(a: boolean) {
        this._authorizedToModify = a;
        this.refreshFormEnabled();
    }

    ngOnInit() {

        // no need to take default values from defaults as this is deprecated
        this.marginForm = this.fb.group({
            generalExpenses: [12, [Validators.min(0), Validators.max(100)]],
            profit: [20, [Validators.min(0), Validators.max(100)]],
            commission: [8.875, [Validators.min(0), Validators.max(100)]],
            customDuties: [0, [Validators.min(0), Validators.max(100)]]
        });
        this.patchForm();

        /**
         * Auto save / commit enabled
         */
        this.marginForm.valueChanges
            .pipe(debounceTime(1000),
                takeUntil(this.unsubscribe$),
                distinctUntilChanged((val1, val2) => {
                    return val1.generalExpenses === val2.generalExpenses &&
                        val1.profit === val2.profit &&
                        val1.commission === val2.commission &&
                        val1.customDuties === val2.customDuties;
                }))
            .subscribe(async result => {
                await this.submit();
            });
    }

    patchForm() {

        if (this._budget && this._budget.margin) {

            if (this._budget.margin.generalExpenses !== undefined &&
                this._budget.margin.profit !== undefined &&
                (<MarginV1>this._budget.margin).commission !== undefined &&
                (<MarginV1>this._budget.margin).customDuties !== undefined) {
                console.log('margin-form: patch form: ', this._budget);
                this.marginForm.patchValue({
                    generalExpenses: this._budget.margin.generalExpenses,
                    profit: this._budget.margin.profit,
                    commission: (<MarginV1>this._budget.margin).commission,
                    customDuties: (<MarginV1>this._budget.margin).customDuties
                });
            }
        }
        this.refreshFormEnabled();
    }

    refreshFormEnabled() {
        if (!this.marginForm) return;

        if (this.authorizedToModify) {
            return this.marginForm.enable();
        }
        if (!BudgetModel.isEditableStatus(this._budget)) {
            return this.marginForm.disable();
        }

        this.auth.userHasPermissions(['app.budget.final.read'])
            .pipe(take(1)).subscribe(val => {
            if (val[0]) this.marginForm.enable();
            else this.marginForm.disable();
        });
    }

    async updateBudget(updateObj: { margin: MarginV1 }) {
        console.log('margin-form: updating budget margin...', updateObj.margin);
        this.formUpdating = true;
        this.marginForm.disable();

        try {
            await this.dataService.patchBudget(this._budget.id, updateObj);
            this.marginForm.enable();
        } catch (err) {
            console.log(err);
            this.marginForm.disable();
        } finally {
            this.formUpdating = false;
        }
    }

    async submit() {
        const margin: MarginV1 = {
            generalExpenses: this.marginForm.value.generalExpenses,
            customDuties: this.marginForm.value.customDuties,
            profit: this.marginForm.value.profit,
            commission: this.marginForm.value.commission
        };
        await this.updateBudget({margin});
        this.updateCostSummary.emit(this._budget.id);
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}

