import {BehaviorSubject, fromEvent, Observable, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatInput} from '@angular/material/input';
import {AppStateService} from '../../services/app-state.service';
import {DataService} from '../../services/data.service';
import {Timereport} from './../interfaces';
import {AuthService} from '../../services/auth.service';
import {FormControl, Validators} from '@angular/forms';
import {Moment} from 'moment';
import * as moment from 'moment-timezone';
import * as XLSX from 'xlsx';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {TimereportListFilter, TimereportListTableService} from '../../services/timereport-list-table.service';



@Component({
    selector: 'timereport-list',
    templateUrl: './timereport-list.component.html',
    styleUrls: ['./timereport-list.component.scss']
})
export class TimereportListComponent implements OnInit, OnDestroy {
    loading = false;

    @ViewChild('fromDate', {
        read: MatInput
    }) fromDate: MatInput;

    @ViewChild('toDate', {
        read: MatInput
    }) toDate: MatInput;

    @ViewChild('reportFromDate', {
        read: MatInput
    }) reportFromDate;

    @ViewChild('reportToDate', {
        read: MatInput
    }) reportToDate;

    @ViewChild('filter', {
        static: true
    }) filter: ElementRef;

    // Set our default values
    public localState = {value: ''};
    public hiddenvalue = 0;
    public timereports: Timereport[] = [];
    public sendReportDisabled = false;
    public dateFromSubject = new BehaviorSubject<Date>(moment().subtract(180, 'days').toDate());
    public dateToSubject = new BehaviorSubject<Date>(moment().toDate());
    public reportDateFromSubject = new BehaviorSubject<Date>(moment().subtract(1, 'year').toDate());
    public reportDateToSubject = new BehaviorSubject<Date>(moment().toDate());
    public canChangeUser;
    public _canDownloadReport: boolean;
    public selectedTab = 0;
    public performingQuickSerch$: Observable<boolean>;
    public performingSlowSearch$: Observable<boolean>;
    public slowListElements: number = 0;
    private unsubscribe$ = new Subject();

    constructor(
        private dataService: DataService,
        private authService: AuthService,
        public dialog: MatDialog,
        public appState: AppStateService,
        private timereportListTableService: TimereportListTableService
    ) {
        this.performingQuickSerch$ = this.timereportListTableService.isLoading$('quick');
        this.performingSlowSearch$ = this.timereportListTableService.isLoading$('slow');

        this.authService.userHasPermission('app.timereport.anyone.write').pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
            this.canChangeUser = value;
        });
    }

    private _sentDates = new Set([]);

    @Input() set sentDates(sd: Set<number>) {
        this._sentDates = sd;
    }

    restoreFilters() {
        const userEmail = (this.canChangeUser) ? null : this.authService.currentUser.email;
        const filter = this.timereportListTableService.retrieveFiltersFromStorage(userEmail);
        this.filter.nativeElement.value = filter.filter;
    }

    storeFilters() {
        const userEmail = (this.canChangeUser) ? null : this.authService.currentUser.email;
        const filter: TimereportListFilter = {
            filter: this.filter.nativeElement.value.trim(),
            toDate: new Date(this.toDate.value),
            fromDate: new Date(this.fromDate.value),
            userEmail: userEmail
        };

        this.timereportListTableService.storeFilters(filter);
    }

    resetDatePicker() {
        const defaultFrom = moment().subtract(180, 'days').toDate();
        const defaultTo = moment().toDate();

        const currentFrom = this.dateFromSubject.value;
        const currentTo = this.dateToSubject.value;

        // Setting time values to 0 to compare only dates and ignore time
        defaultFrom.setHours(0, 0, 0, 0);
        defaultTo.setHours(0, 0, 0, 0);
        currentFrom.setHours(0, 0, 0, 0);
        currentTo.setHours(0, 0, 0, 0);

        // Check to prevent multiple db calls on subsequent clicks
        if (currentFrom.getTime() !== defaultFrom.getTime() || currentTo.getTime() !== defaultTo.getTime()) {
            this.dateFromSubject.next(defaultFrom);
            this.dateToSubject.next(defaultTo);
        }

        this.storeFilters();
    }

    public ngOnInit() {
        this.authService.userHasPermission('app.timereport.report.download')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((val: boolean) => {
                this._canDownloadReport = val;
            });

        const filterFevent$ = fromEvent(this.filter.nativeElement, 'input').pipe(
            takeUntil(this.unsubscribe$), debounceTime(500)
        ).subscribe(() => this.storeFilters());
    }

    ngAfterViewInit() {
        this.restoreFilters();
        this.resetDatePicker();
        this.storeFilters();
    }

    sendDailyReport() {

        if (!this.authService.currentUser) {
            this.appState.showError({errorMessage: 'Current user not defined', errorCode: -1});
            return;
        }

        this.sendReportDisabled = true;
        const dialogRef = this.dialog.open(SendReportDialogComponent, {
            width: '550px',
            data: {
                sentDates: this._sentDates,
                canChangeUser: this.canChangeUser,
                user: this.authService.currentUser
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed ', result);
            // this.animal = result;
            if (!result) {
                this.sendReportDisabled = false;
                return;
            }
            this.dataService.sendDailyReport(result)
                .subscribe((data: any) => {
                    console.log('Report sent', data);
                    this.sendReportDisabled = false;
                    this.appState.infoDialog({
                        infoMessage: data.message ? data.message : 'Report sent',
                        infoHtmlDetail: data.emailBody
                    });
                }, (err) => {
                    this.sendReportDisabled = false;
                    this.appState.showError({errorMessage: err.message, errorCode: -1});
                });
        });
    }

    async downloadHourReport() {

        const from = this.reportFromDate.value.toDate();
        const to = this.reportToDate.value.toDate();
        console.log('Creating excel with.. ', from, to);

        try {
            this.loading = true;
            const reportData = await this.dataService.getTimereportsReport(from, to).toPromise();
            console.log('Got data: ', reportData);

            const wb = XLSX.utils.book_new();

            /**
             * Sheet 1
             */

            const sheetData1 = [reportData.firstSheetHeader];
            Object.keys(reportData.report1).map(key => {
                sheetData1.push([
                    key,
                    reportData.report1[key].category,
                    reportData.report1[key].hours
                ])
            });

            const ws1 = XLSX.utils.aoa_to_sheet(sheetData1);
            ws1['!cols'] = reportData.firstSheetHeader.map(i => {
                return {wch: i.length};
            });
            XLSX.utils.book_append_sheet(wb, ws1, 'Overview');

            /**
             * Sheet 2
             */

            const sheetData2 = [reportData.secondSheetHeader];
            Object.keys(reportData.report2).forEach(nomeUtente => {

                const row: any[] = [nomeUtente];

                for (const key of reportData.secondSheetHeader) {
                    if (key === 'Nome Utente') continue;
                    reportData.report2[nomeUtente][key] ? row.push(reportData.report2[nomeUtente][key]) : row.push(0);
                }

                sheetData2.push(row);
            });

            const ws2 = XLSX.utils.aoa_to_sheet(sheetData2);
            XLSX.utils.book_append_sheet(wb, ws2, 'Ore utenti');
            ws2['!cols'] = reportData.secondSheetHeader.map(i => {
                return {wch: i.length}
            });

            const fileName = `${this.reportFromDate.value.format('DDMMYYYY')}_${this.reportToDate.value.format('DDMMYYYY')}_centri_di_costo_export.xlsx`;
            XLSX.writeFile(wb, fileName);
            this.loading = false;
        } catch (err) {
            console.error(err);
            this.loading = false;
        }

    }

    public ngOnDestroy() {
        this.unsubscribe$.unsubscribe();
    }
}

@Component({
    selector: 'app-send-report-dialog',
    template: `<h3 mat-dialog-title i18n>Send Report</h3>
    <mat-dialog-content>
        <app-user-picker placeholder="Select an user" [myControl]="employee"
                         [readonly]="userReadOnly"></app-user-picker>
        <mat-form-field>
            <input matInput [max]="maxDate"
                   [matDatepickerFilter]="dateFilter"
                   [matDatepicker]="picker" placeholder="Report Date" i18n-placeholder required
                   [formControl]="reportDateControl" name="deliveryDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button type="submit" mat-button (click)="closeDialog()" i18n>Send report</button>
    </mat-dialog-actions>
    `,
    providers: [
        {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}
    ]
})
export class SendReportDialogComponent implements OnInit {

    public employee: FormControl = new FormControl(undefined, Validators.required);
    public reportDateControl: FormControl = new FormControl(undefined, Validators.required);
    public maxDate = new Date();

    public userReadOnly = false;

    constructor(
        public dialogRef: MatDialogRef<SendReportDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
        if (!this.data.canChangeUser) {
            this.employee.setValue(this.data.user);
            this.userReadOnly = true;
        } else {
            this.dateFilter = undefined;
        }
    }

    public dateFilter = (d: Moment | null): boolean => {
        if (!this.data.sentDates) {
            return false;
        }

        // the existingTimereportTs is CEST, the calendar's date is always midnight locale (so usually US or Italy).
        // Comparing timestamps is not enough, as the existingTimereportTs would be fine in italy and like 15:00 of day before in USA!
        // As we only care if its the same day, we force Rome timezone and then compare the day.
        for (const existingTimereportTs of <Set<number>>this.data.sentDates) {
            console.log(moment(existingTimereportTs).toString(), moment.tz(existingTimereportTs, 'Europe/Rome').toString(), d.toString());
            if (moment.tz(existingTimereportTs, 'Europe/Rome').isSame(d, 'day')) return false;
        }
        return true;
        // previous solution ----> return !this.data.sentDates.has(d.valueOf());
    }

    closeDialog() {
        if (this.validateForm()) {
            this.dialogRef.close({
                user: this.data.canChangeUser ? this.employee.value : this.data.user,
                reportDate: this.reportDateControl.value
            });
        } else {
            console.log('Invalid');
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    private validateForm() {
        return this.reportDateControl.valid && (!this.data.canChangeUser || this.employee.valid);
    }

}
