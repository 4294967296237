<div class="row">
    <h2>Admin page</h2>
</div>

<mat-tab-group>

    <mat-tab>
        <ng-template mat-tab-label>
            <span>Activities blueprints</span>
        </ng-template>

        <app-blueprints></app-blueprints>

    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <span>Margin defaults</span>
        </ng-template>

        <div class="give-margin-cell">
            <h4>Default margins for EXPORT budgets:</h4>
            <app-margins [isExport]="true"></app-margins>
        </div>
        <div class="give-margin-cell">
            <h4>Default margins for ITA budgets:</h4>
            <app-margins [isExport]="false"></app-margins>
        </div>

    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <span>Reporting</span>
        </ng-template>

        <app-reporting></app-reporting>

    </mat-tab>

</mat-tab-group>
