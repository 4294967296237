<div *ngIf="formUpdating" class="margin-form-overlay">
    <mat-spinner diameter=32></mat-spinner>
</div>
<form (ngSubmit)="submit()" [formGroup]="marginForm" class="margin-form-container">
    <mat-form-field class="mb-1">
        <input formControlName="generalExpenses" i18n-placeholder matInput placeholder="General Expenses" type="number">
        <span matSuffix>%</span>
        <mat-error *ngIf="marginForm.hasError('marginSumExceeded') && marginForm.get('generalExpenses').touched">
            General Expenses + Commissions + Profit must be less than 100
        </mat-error>
    </mat-form-field>
    <mat-form-field class="mb-1">
        <input formControlName="commission" i18n-placeholder matInput placeholder="Commissions" type="number">
        <span matSuffix>%</span>
        <mat-error *ngIf="marginForm.hasError('marginSumExceeded') && marginForm.get('commission').touched">
            General Expenses + Commissions + Profit must be less than 100
        </mat-error>
    </mat-form-field>
    <div *ngIf="kind === 'USA'" class="row-start">
        <mat-form-field class="mb-1">
            <input formControlName="ciTaxesPercentage" i18n-placeholder matInput placeholder="Capital Improvement Taxes"
                   type="number">
            <span matSuffix>%</span>
        </mat-form-field>
        <mat-slide-toggle formControlName="capitalImprovement" style="margin-left: 10px">
        </mat-slide-toggle>
    </div>
    <mat-form-field class="mb-1">
        <input formControlName="profit" i18n-placeholder matInput placeholder="Profit" type="number">
        <span matSuffix>%</span>
        <mat-error *ngIf="marginForm.hasError('marginSumExceeded') && marginForm.get('profit').touched">
            General Expenses + Commissions + Profit must be less than 100
        </mat-error>
    </mat-form-field>
    <button (submit)="submit()" [disabled]="!marginForm?.valid" mat-button type="submit">Save</button>
</form>
